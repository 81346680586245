import styled from 'styled-components'

export const FileUploadWrapper = styled.div`
  display: flex;
  align-items: center;

  .ant-upload-list-item {
    height: 100%;
  }

  .ant-upload-list-item-name {
    width: 20rem;
    white-space: wrap;
  }
`
