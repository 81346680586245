import { FC, useState } from 'react'
import { useModalContext } from '@netpurpose/core'
import { Button, Image, Modal, Text } from '@netpurpose/np-ui'
import { ModalActionTrigger } from '#components/portfolio/portfolioTypes'
import { useAnalytics } from '#context/analytics'
import { AnalyticsButtonName, AnalyticsEventName } from '#types/analytics'
import { CreatePortfolioContent } from '../CreatePortfolioAction'
import { DownloadTemplateButton } from '../DownloadTemplateButton'
import { ImageContainer } from './UploadHelperAction.style'

export const UploadHelperContent = () => {
  const [showCreatePortfolioForm, setShowCreatePortfolioForm] = useState<boolean>(false)

  const analytics = useAnalytics()

  const onDownloadTemplate = () =>
    analytics?.logEvent(AnalyticsEventName.ButtonClick, {
      button_name: AnalyticsButtonName.DownloadPortfolioTemplate,
      location: 'create_portfolio_learn_more',
    })

  if (showCreatePortfolioForm) {
    return <CreatePortfolioContent />
  }

  return (
    <>
      <Modal.ContentContainer>
        <Modal.SpacedContent>
          <Modal.HeaderText>
            A short guide to creating a portfolio in the Net Purpose app
          </Modal.HeaderText>
          <Modal.LightweightText color="typeSecondary">
            The Net Purpose app allows you to create and update multiple portfolios and benchmarks
            through a simple file upload. All you need to do is{' '}
            <strong>
              provide a CSV file with a list of your holdings with holding weights or values
            </strong>{' '}
            e.g. generated from your portfolio management system. Please see below for a sample file
            format.
          </Modal.LightweightText>
        </Modal.SpacedContent>
      </Modal.ContentContainer>
      <Modal.ContentContainer>
        <Modal.SpacedContent>
          <Modal.HeaderText>On the &quot;Create your Portfolio&quot; screen:</Modal.HeaderText>
          <ul>
            <li>
              <Text color="typeSecondary">Enter the name of your portfolio</Text>
            </li>
            <li>
              <Text color="typeSecondary">Select the type (either Portfolio or Benchmark)</Text>
            </li>
            <li>
              <Text color="typeSecondary">Select the base currency of the portfolio</Text>
            </li>
            <li>
              <Text color="typeSecondary">Input the portfolio valuation and valuation date</Text>
            </li>
            <li>
              <Text color="typeSecondary">
                Click &apos;Choose file&apos; and select the CSV from the appropriate location
              </Text>
            </li>
          </ul>
          <Modal.LightweightText color="typeSecondary">
            Finally, hit &apos;Create Portfolio&apos;. You can see the list of portfolios, including
            the one you just created, under the Portfolios tab in the header.
          </Modal.LightweightText>
          <DownloadTemplateButton onDownloadTemplate={onDownloadTemplate} />
          <ImageContainer>
            <Image name="example_csv.png" />
          </ImageContainer>
        </Modal.SpacedContent>
      </Modal.ContentContainer>
      <Modal.FooterContainer>
        <Button level="primary" onClick={() => setShowCreatePortfolioForm(true)}>
          Create Now
        </Button>
      </Modal.FooterContainer>
    </>
  )
}

export const UploadHelperModal: FC = () => {
  const { isModalOpen, closeModal } = useModalContext()

  return (
    <Modal
      open={isModalOpen.portfolioUploadHelper}
      title="Creating a Portfolio"
      onClose={() => closeModal('portfolioUploadHelper')}
    >
      <UploadHelperContent />
    </Modal>
  )
}

type Props = {
  renderAction: FC<ModalActionTrigger>
}

export const UploadHelperAction: FC<Props> = ({ renderAction: Action }) => {
  const { openModal } = useModalContext()

  return <Action onClick={() => openModal('portfolioUploadHelper')} />
}
