import { UseMutateFunction } from '@tanstack/react-query'
import endOfDay from 'date-fns/endOfDay'
import { Formik } from 'formik'
import { Form } from 'formik-antd'
import { FC } from 'react'
import * as yup from 'yup'
import {
  Button,
  DatePicker,
  ErrorMessage,
  FileUpload,
  Input,
  Modal,
  NumberInput,
  Select,
  SelectOption,
} from '@netpurpose/np-ui'
import { CreatePortfolio } from '@netpurpose/types'
import { DownloadTemplateButton } from '../DownloadTemplateButton'
import { FileUploadWrapper } from './CreatePortfolioAction.style'

type InputFieldsProps = {
  onDownloadTemplate: () => void
  onRemove: () => void
}

const InputFields: FC<InputFieldsProps> = ({ onDownloadTemplate, onRemove }) => (
  <>
    <Form.Item
      name="name"
      label="Portfolio name*"
      htmlFor="name-input"
      wrapperCol={{ span: 10, offset: 2 }}
    >
      <Input
        id="name-input"
        name="name"
        placeholder="Enter portfolio name"
        size="large"
        suffix={<span />}
      />
    </Form.Item>
    <Form.Item name="type" label="Type" wrapperCol={{ span: 10, offset: 2 }}>
      <Select name="type" defaultValue="Private" size="large">
        <SelectOption value="private">Portfolio</SelectOption>
        <SelectOption value="benchmark">Benchmark</SelectOption>
      </Select>
    </Form.Item>
    <Form.Item name="currency" label="Currency" wrapperCol={{ span: 10, offset: 2 }}>
      <Select name="currency" size="large">
        <SelectOption value="USD">USD</SelectOption>
      </Select>
    </Form.Item>
    <Form.Item
      name="totalValue"
      label="Total value"
      htmlFor="total-value-input"
      wrapperCol={{ span: 10, offset: 2 }}
    >
      <NumberInput
        id="total-value-input"
        name="totalValue"
        size="large"
        type="number"
        controls={false}
      />
    </Form.Item>
    <Form.Item name="valuationDate" label="Valuation date" wrapperCol={{ span: 10, offset: 2 }}>
      <DatePicker name="valuationDate" size="large" />
    </Form.Item>
    <Form.Item
      name="file"
      label={
        <div>
          Upload file
          <DownloadTemplateButton onDownloadTemplate={onDownloadTemplate} />
        </div>
      }
    >
      <FileUploadWrapper>
        <FileUpload name="file" accept=".csv, .xlsx" data-testid="file-input" onRemove={onRemove}>
          <Button>Choose file</Button>
        </FileUpload>
      </FileUploadWrapper>
    </Form.Item>
  </>
)

type Props = {
  onSubmit: UseMutateFunction<string, unknown, CreatePortfolio, unknown>
  onDownloadTemplate: () => void
  errorMessage: string | null
  onCancel: () => void
  isSubmitting: boolean
}
type SubComponents = {
  InputFields: typeof InputFields
}

const CreatePortfolioForm: FC<Props> & SubComponents = ({
  onSubmit,
  onDownloadTemplate,
  errorMessage,
  onCancel,
  isSubmitting,
}) => (
  <Formik
    initialValues={{
      name: '',
      type: 'private',
      currency: 'USD',
      valuationDate: new Date(),
      // Casting to unknown because of a type mismatch between initial values and submitted values
      // totalValue and file cannot be undefined but we want them to be empty form fields initially
      totalValue: undefined as unknown as number,
      file: undefined as unknown as File,
    }}
    validationSchema={yup.object({
      name: yup.string().required('Required'),
      type: yup.string().required('Required'),
      currency: yup.string().required('Required'),
      totalValue: yup
        .number()
        .required('Required')
        .positive("If you don't want to specify your AUM, you can use 1,000,000."),
      valuationDate: yup
        .date()
        .required('Required')
        .max(endOfDay(new Date()), 'Valuation date cannot be in the future.'),
      file: yup.mixed().required('Required'),
    })}
    onSubmit={(vals: CreatePortfolio) => onSubmit(vals)}
  >
    {({ setFieldValue }) => (
      <Form
        layout="horizontal"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 6, offset: 2 }}
        labelAlign="left"
        colon={false}
      >
        <Modal.ContentContainer>
          <InputFields
            onDownloadTemplate={onDownloadTemplate}
            onRemove={() => setFieldValue('file', null)}
          />
          {errorMessage && (
            <ErrorMessage error={`Portfolio could not be created: ${errorMessage}`} />
          )}
        </Modal.ContentContainer>
        <Modal.FooterContainer>
          <Button level="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            level="tertiarySubmit"
            data-testid="create-portfolio-form-submit-button"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Create Portfolio
          </Button>
        </Modal.FooterContainer>
      </Form>
    )}
  </Formik>
)

InputFields.displayName = 'CreatePortfolioForm.InputFields'
CreatePortfolioForm.InputFields = InputFields

export default CreatePortfolioForm
