import { useQueryClient } from '@tanstack/react-query'
import { FC, useState } from 'react'
import { useApi } from '@netpurpose/api'
import { useModalContext } from '@netpurpose/core'
import { Button, ErrorMessage, Icon, Modal, Text, withTooltip } from '@netpurpose/np-ui'
import { MultiplePortfolioActionProps } from '#components/portfolio/portfolioTypes'
import { DeleteButtonContainer } from './DeletePortfolioAction.style'

export const DeletePortfolioModal: FC<MultiplePortfolioActionProps> = ({
  selectedPortfolioIds,
  setSelectedPortfolioIds,
}) => {
  const queryClient = useQueryClient()
  const { api } = useApi()
  const [error, setError] = useState<string | null>(null)
  const { isModalOpen, closeModal: closeActualModal } = useModalContext()
  const closeModal = () => closeActualModal('deletePortfolio')

  const deletePortfolios = async () => {
    try {
      await Promise.all(
        selectedPortfolioIds.map(async (portfolioId) => {
          await api.portfolio.delete(portfolioId)
        }),
      )
      queryClient.invalidateQueries({
        queryKey: ['portfolios'],
      })
      setSelectedPortfolioIds([])
      closeModal()
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message?.toUpperCase())
      }
    }
  }

  return (
    <Modal
      open={isModalOpen.deletePortfolio}
      title="Delete Portfolio"
      onClose={closeModal}
      size="medium"
    >
      <Modal.ContentContainer>
        <Modal.HeaderText>Are you sure?</Modal.HeaderText>
        <Text color="typeSecondary">
          You are going to delete selected portfolio(s). This action is irreversible - are you sure
          you want to do this?
        </Text>
        {error && <ErrorMessage error={error} />}
      </Modal.ContentContainer>
      <Modal.FooterContainer>
        <Button level="danger" onClick={deletePortfolios}>
          Delete permanently
        </Button>
        <Button level="primary" onClick={closeModal}>
          Cancel
        </Button>
      </Modal.FooterContainer>
    </Modal>
  )
}

const DeleteButtonWrapper = withTooltip(DeleteButtonContainer)

export const DeletePortfolioAction: FC<
  Pick<MultiplePortfolioActionProps, 'selectedPortfolioIds'>
> = (props) => {
  const { openModal } = useModalContext()
  const { selectedPortfolioIds } = props

  const isDisabled = selectedPortfolioIds.length === 0
  const handleClick = () => {
    if (isDisabled) {
      return
    }
    openModal('deletePortfolio')
  }

  return (
    <>
      <DeleteButtonWrapper
        data-testid="delete-button"
        disabled={isDisabled}
        onClick={handleClick}
        tooltip="Select a portfolio to delete"
        showTooltip={isDisabled}
      >
        <Icon
          icon="Delete"
          alt="Delete portfolio"
          color="semanticNegative"
          style={isDisabled ? { opacity: 0.4 } : {}}
        />
      </DeleteButtonWrapper>
    </>
  )
}
