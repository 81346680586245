import { Portfolio } from '@netpurpose/api'
import { PortfolioType, ResultStatus } from '@netpurpose/types'
import { formatDateForTable } from '@netpurpose/utils'
import { PortfolioCreationTask } from '#context/tasks'

export type PortfolioForDisplay = Pick<Portfolio, 'id' | 'name' | 'value' | 'valueCovered'> & {
  resultStatus: ResultStatus
  type: PortfolioType
  valuationDate: string
  numHoldings?: number
  numHoldingsCovered?: number
}

export const formatPortfolioCreationTask = (
  task: PortfolioCreationTask,
  index: number,
): PortfolioForDisplay => ({
  id: index,
  resultStatus: task.status,
  name: task.initial.name,
  type: task.initial.type,
  value: task.initial.totalValue,
  valuationDate: formatDateForTable(task.initial.valuationDate),
})

export const formatPortfolio = (portfolio: Portfolio): PortfolioForDisplay => ({
  id: portfolio.id,
  resultStatus: 'complete',
  name: portfolio.name,
  type: portfolio.type,
  ...(portfolio.value ? { value: portfolio.value } : {}),
  ...(portfolio.valueCovered ? { valueCovered: portfolio.valueCovered } : {}),
  valuationDate: formatDateForTable(portfolio.valuationDate),
  numHoldings: portfolio.numHoldings,
  numHoldingsCovered: portfolio.numHoldingsCovered,
})
